import revive_payload_client_4sVQNw7RlN from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/stcomuser/htdocs/satyatunes.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/stcomuser/htdocs/satyatunes.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/stcomuser/htdocs/satyatunes.com/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_dw2T9lEw4h from "/home/stcomuser/htdocs/satyatunes.com/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_jBToDIZpFa from "/home/stcomuser/htdocs/satyatunes.com/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import dompurify_html_QCD4VaYfz4 from "/home/stcomuser/htdocs/satyatunes.com/plugins/dompurify-html.js";
import locally_Jp88xfIZzn from "/home/stcomuser/htdocs/satyatunes.com/plugins/locally.js";
import vuetify_8NhHJigKc1 from "/home/stcomuser/htdocs/satyatunes.com/plugins/vuetify.js";
import defaults_aRl2YLnTcb from "/home/stcomuser/htdocs/satyatunes.com/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_jBToDIZpFa,
  dompurify_html_QCD4VaYfz4,
  locally_Jp88xfIZzn,
  vuetify_8NhHJigKc1,
  defaults_aRl2YLnTcb
]