<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

const prps = defineProps({
    btnText: {
        type: String, // Text on the button
        default: null,
    },
    btnIcon: {
        type: String, // Icon in lieu of text
        default: null,
    },
    btnColor: {
        type: String, // Default button color
        default: null, //'primary',
    },
    showTooltip: {
        type: Boolean, // Whether to show tooltip or not
        default: false,
    },
    tooltipText: {
        type: String, // Text for tooltip
        default: null,
    },
})
const { btnColor } = toRefs(prps)

const bgColor = computed(() => {
    if (theme.global.name.value == 'sTDarkTheme') {
        if (btnColor.value) {
            // console.log(btnColor.value)
            return btnColor.value
        } else {
            return 'sthighlight'
        }
    } else {
        return 'primary'
    }
})
</script>

<template>
    <div>
        <v-hover>
            <template v-slot:default="{ isHovering, props }">
                <v-btn
                    :class="!prps.btnText ? 'd-flex justify-center' : null"
                    v-bind="props"
                    v-bind.prop="$attrs"
                    :color="isHovering ? 'secondary' : bgColor"
                >
                    {{ prps.btnText ? prps.btnText : null }}
                    <v-icon v-if="!prps.btnText" :icon="prps.btnIcon"></v-icon>
                    <v-tooltip v-if="prps.showTooltip" activator="parent" location="top">{{
                        prps.tooltipText
                    }}</v-tooltip>
                </v-btn>
            </template>
        </v-hover>
    </div>
</template>

<style scoped></style>
