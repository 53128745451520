<script setup>
import { useDisplay } from 'vuetify'

// mobile breakpoint
const { xs } = useDisplay()

// error object by nuxt
const props = defineProps({
    error: Object,
})

// reset errors before navigating to home page
const handleError = () => clearError({ redirect: '/' })
</script>

<template>
    <v-container id="stvueerror">
        <v-row class="slide-from-top">
            <v-col>
                <v-card variant="flat" class="pa-10 d-flex flex-column align-center justify-center">
                    <v-icon icon="mdi-alert-circle" color="error" :size="xs ? 90 : 120"> </v-icon>
                    <v-card-title class="pa-8 text-center text-h2 text-sm-h1 text-error">
                        {{ error.statusCode }}
                    </v-card-title>
                    <v-card-subtitle class="pa-2 text-center text-h5 text-error">
                        {{ error.message }}
                    </v-card-subtitle>
                    <v-card-text v-if="!error.message" class="mt-4 text-center">
                        <v-row>
                            <v-col cols="12" class="text-h6 text-error">
                                Looks like something went wrong. Please try again later.
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <app-button :btn-text="`Back to Home`" :size="`x-large`" @click.stop="handleError" class="mt-4" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.slide-from-top {
    animation: slideFromTop 0.45s ease-out both;
}

@keyframes slideFromTop {
    0% {
        transform: translate3d(0, -100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
</style>
