<script setup>
useSeoMeta({
    title: 'Discover free skins, themes, plugins, deals and soundsets. Sell and promote your product.',
    // ogTitle: 'Discover free skins, themes, plugins, deals and soundsets. Sell and promote your product.',
    description:
        'Discover free skins, themes, plugins, deals and soundsets. Sell and promote your product on satYatunes.',
    //ogDescription:
    //    'Discover free skins, themes, plugins, deals and soundsets. Sell and promote your product on satYatunes.',
    ogImage: 'https://satyatunes.com/wbst-ogimg-01.jpg',
    twitterCard: 'summary_large_image',
    twitterSite: '@satYatunes',
})
useHead({
    titleTemplate: 'satYatunes - %s',
    meta: [
        { name: 'impact-site-verification', content: '6682831f-c882-4f14-965c-f8d85e5ba76b' },
        { name: 'yandex-verification', content: '5ee41793b3a271d0' },
    ],
})
</script>

<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>
