import { default as aboutEG4vJMnKtnMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/about.vue?macro=true";
import { default as cancelG7OsFwx0eZMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/cancel.vue?macro=true";
import { default as cartWWcACPIOexMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/cart.vue?macro=true";
import { default as checkoutyoQMmIuoakMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/checkout.vue?macro=true";
import { default as cookiedZY0pz8kIkMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/cookie.vue?macro=true";
import { default as indexv5ioKnEDgsMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/deals/index.vue?macro=true";
import { default as failed6gW1chtK90Meta } from "/home/stcomuser/htdocs/satyatunes.com/pages/failed.vue?macro=true";
import { default as faqstcmzcNH9y0Meta } from "/home/stcomuser/htdocs/satyatunes.com/pages/faqs.vue?macro=true";
import { default as indexQgbbtXv0coMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/index.vue?macro=true";
import { default as offersD4oB7iLDXPMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/offers.vue?macro=true";
import { default as privacyAA6yAAzt3mMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/privacy.vue?macro=true";
import { default as _91handle_93T6P9oYAHONMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/seller/[handle].vue?macro=true";
import { default as customgui0G3iB2OjNzMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/services/customgui.vue?macro=true";
import { default as indexkiibnwT3rCMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/services/index.vue?macro=true";
import { default as _91slug_93PN5y1ayv63Meta } from "/home/stcomuser/htdocs/satyatunes.com/pages/shop/[slug].vue?macro=true";
import { default as indexkuIPnjzmBcMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/shop/index.vue?macro=true";
import { default as successG5CwWWwcvXMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/success.vue?macro=true";
import { default as supportfUU58zBF6aMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/support.vue?macro=true";
import { default as termsF8lmUBBqfJMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/terms.vue?macro=true";
import { default as unsubscribellqo9YQvobMeta } from "/home/stcomuser/htdocs/satyatunes.com/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/about.vue")
  },
  {
    name: "cancel",
    path: "/cancel",
    meta: cancelG7OsFwx0eZMeta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/cancel.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: cartWWcACPIOexMeta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/cart.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutyoQMmIuoakMeta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/checkout.vue")
  },
  {
    name: "cookie",
    path: "/cookie",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/cookie.vue")
  },
  {
    name: "deals",
    path: "/deals",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/deals/index.vue")
  },
  {
    name: "failed",
    path: "/failed",
    meta: failed6gW1chtK90Meta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/failed.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/faqs.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/index.vue")
  },
  {
    name: "offers",
    path: "/offers",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/offers.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/privacy.vue")
  },
  {
    name: "seller-handle",
    path: "/seller/:handle()",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/seller/[handle].vue")
  },
  {
    name: "services-customgui",
    path: "/services/customgui",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/services/customgui.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/services/index.vue")
  },
  {
    name: "shop-slug",
    path: "/shop/:slug()",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/shop/[slug].vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/shop/index.vue")
  },
  {
    name: "success",
    path: "/success",
    meta: successG5CwWWwcvXMeta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/success.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supportfUU58zBF6aMeta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/support.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/terms.vue")
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    meta: unsubscribellqo9YQvobMeta || {},
    component: () => import("/home/stcomuser/htdocs/satyatunes.com/pages/unsubscribe.vue")
  }
]