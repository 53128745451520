import 'vuetify/styles'
import '@/assets/settings.scss'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'

import colors from 'vuetify/util/colors'

export default defineNuxtPlugin((nuxtApp) => {
    // sT light theme properties
    const sTLightTheme = {
        dark: false,
        colors: {
            background: '#FFFFFF',
            surface: '#FAFAFA',
            primary: '#212121',
            secondary: '#DD2C00', // deep-orange-accent-4
            error: '#f44336',
            info: '#2196F3',
            success: '#4caf50',
            warning: '#fb8c00',
            stgrey: '#F5F5F5',
            sthighlight: '#FBC02D', // yellow-darken-2
        },
    }
    // sT dark theme properties
    const sTDarkTheme = {
        dark: true,
        colors: {
            background: '#121212',
            surface: '#222222',
            primary: '#f5f5f5', //1976d2', //039BE5', // light-blue-darken-1
            secondary: '#DD2C00',
            error: '#f44336',
            info: '#2196F3',
            success: '#4caf50',
            warning: '#fb8c00',
            sthighlight: '#FBC02D', // yellow-darken-2
        },
    }
    const vuetify = createVuetify({
        ssr: true,
        components,
        directives,
        theme: {
            defaultTheme: 'sTDarkTheme',
            themes: {
                sTDarkTheme,
                sTLightTheme,
            },
        },
    })

    nuxtApp.vueApp.use(vuetify)
})
